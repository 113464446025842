import React, { useState, useEffect } from "react";
import { IntlProvider } from "react-intl";
import { flattenMessages } from "../../utils/messagesUtils";
import BrandingService from "../BrandingService";
import { BrandingContext } from "./BrandingContext";
import StylingProvider from "./StylingProvider";
import getBrandName from "../../utils/getBrandName";
import { useWPPOs } from '../wpp/GetWPPOsContextData';
import getWPPThemeJson from '../wpp/theme/getWPPThemeJson';

const BrandingProviderWPP = ({ children }) => {

  const [config, setConfig] = useState({ isDefault: true });
  const [messages, setMessages] = useState({ isDefault: true });
  const [isLoading, setIsLoading] = useState(true);
  const [theme, setTheme] = useState({});

  let brandname = getBrandName();

  let shouldUseOsContext = (brandname === "wpp" && process.env.NODE_ENV === "production") ? true : false;  

  const osContextData = useWPPOs();
  const osContext = osContextData.osContext;
  const osApi = osContextData.osApi;
  const osToken = osContextData.osToken;
  console.log("BrandingProviderWPP::branding provider CS:: ---::", osContext, osApi, osToken);
console.log('osContextData from provider::::', osContextData)
  useEffect(() => {
    setIsLoading(true);

    BrandingService.get().then(async (result) => {

      let configResult = result[0];
      let messagesResult = result[1];
      let themeResult;


      if (shouldUseOsContext) {

        let themeObj = getWPPThemeJson(osContext);
        console.log("cs provider BrandingProviderWPP::themeObj", themeObj);
        themeResult = themeObj;

      }
      else {
        themeResult = result[0]?.theme;
      }

     
      updateStatesAndCallSetBodyFonts1(configResult, themeResult, messagesResult);

    });
  }, []);

  const setBodyFonts = () => {
    console.log("BrandingProviderWPP::setBodyFonts:::config CS", config.fonts);
    var fonts = document.createElement("style");
    fonts.appendChild(document.createTextNode(config.fonts));
    document.head.appendChild(fonts);
  };

  async function updateStatesAndCallSetBodyFonts1(configResult, themeResult, messagesResult) {
    console.log("BrandingProviderWPP CS::updateStatesAndCallSetBodyFonts1:::configResult", configResult);
    await Promise.all([
      setConfig(configResult),
      setTheme(themeResult),
      setMessages(messagesResult),
      setIsLoading(false)
    ]);

    setBodyFonts();
  }

  const renderLoading = () => (
    <div
      style={{
        backgroundColor: "#000",
        height: "100vh",
        color: "var(--color-white)",
      }}
    >
      Loading...
    </div>
  );

  if (isLoading) {
    return renderLoading();
  }

  const state = {
    config,
    messages,
    theme,
    osContext,
    osApi,
    osToken
  };

  

  return (
    <BrandingContext.Provider value={state}>
      <IntlProvider locale="en" messages={flattenMessages(messages)}>
        <StylingProvider stylingVariables={theme}>{children}</StylingProvider>
      </IntlProvider>
    </BrandingContext.Provider>
  );
};

export default BrandingProviderWPP;