import React from "react";
import {connectToParent } from 'penpal';
import { useContext, useState, useEffect, createContext } from 'react';
import getBrandName from '../../utils/getBrandName';

const OsContext = createContext(null);

export const WPPOsProvider = ({ children }) => {
    const [osApiValue, setOsApiValue] = useState(null);
    const [osContextValue, setOsContextValue] = useState(null);
    const [osToken, setOsToken] = useState(null);

    console.log("CS WPPOsProvider::--brandname", getBrandName());

    useEffect(() => {
        console.log(" CS Initial osContextValue:", osContextValue, "osApiValue:", osApiValue);

        if (osContextValue == null || osApiValue == null) {
            const methods = {
                receiveOsContext: (osContext) => {
                    console.log('CS %creceiveOsContext', 'padding:5px 10px; background: #ffaa8c;');
                    console.log(osContext);
                    setOsContextValue(osContext);
                },
            };

            const connection = connectToParent({
                parentOrigin: '*',
                methods,
                debug: true,
            });

            connection.promise.then(({ osApi }) => {
                console.log('CS %cChild received api methods ', 'padding:5px 10px; background: #eda9d2;');
                console.log('CS OSAPI DATA::::', osApi);
                setOsApiValue(osApi);
                osApi.getAccessToken().then((token) => {
                    console.log('CS %cGet token', 'padding:5px 10px; background: #96d8be;');
                    console.log('CS getToken::::', token);
                    setOsToken(token);
                }).catch((error) => {
                    console.error('CS Penpal child context error:', error);
                });
            }).catch((error) => {
                console.error('CS Penpal child context error:', error);
            });

            return () => {
                console.log('CS %cDestroy child connection', 'padding:5px 10px; background: #96d8be;');
                connection.destroy();
            };
        }
    }, []);

    console.log('CS osContextValue-osApiValue', osContextValue, osToken);



    if ((!osContextValue || !osToken) && getBrandName() === 'wpp') {
        return <>
            <p>Loading...</p>
        </>;
    }

    return (
        <>
            {getBrandName() === 'wpp' ? (
                <OsContext.Provider
                    value={{
                        osContext: osContextValue,
                        osApi: osApiValue,
                        osToken: osToken
                    }}
                >
                    {children}
                </OsContext.Provider>
            ) : (
                <>
                    {children}
                </>
            )}
        </>
    );
};



export const useWPPOs = () => {    
    const context = useContext(OsContext);
    return context;
};