import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  notificationTopBarMeta: { isVisible: false, isClosed: false, msg: "" },
};

const notificationTopBarSlice = createSlice({
  name: "NOTIFICATION_TOP_BAR",
  initialState,
  reducers: {
    SET_NOTIFICATION_TOP_BAR: (state, action) => {
      state.notificationTopBarMeta = action.payload;
    },
    RESET_NOTIFICATION_TOP_BAR: () => initialState,
  },
});

export default notificationTopBarSlice.reducer;
export const { SET_NOTIFICATION_TOP_BAR, RESET_NOTIFICATION_TOP_BAR } =
  notificationTopBarSlice.actions;
