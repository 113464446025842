import React, { useEffect } from "react";
import "./index.css";
import BrandingProvider from "./branding/provider/BrandingProvider";
import { persistor, store } from "./reduxToolkit/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import Router from "./routes/components/Router";
import ClearCache from "react-clear-cache";
import getBrandName from "./utils/getBrandName";
import { WPPOsProvider } from "./branding/wpp/GetWPPOsContextData";
import BrandingProviderWPP from "./branding/provider/BrandingProviderWPP";

const App = () => {
  let brandName = getBrandName();

  useEffect(() => {
    localStorage.removeItem("brandName");
    const brandName = getBrandName();
    let isBrandClassNameAddedToBody =
      document.body.classList.contains(brandName);
    if (!isBrandClassNameAddedToBody) {
      document.body.classList.add(brandName);
    }
  }, []);

  console.log(brandName,'Brandname cs')
  return (
    <ClearCache auto={true}>
      {({ isLatestVersion, emptyCacheStorage }) => {
        if (!isLatestVersion) {
          emptyCacheStorage();
        }
        return (
          <Provider store={store}>
            <PersistGate persistor={persistor}>
              {brandName == "wpp" && process.env.NODE_ENV === "production" ? (
                <WPPOsProvider>
                <BrandingProviderWPP>
                <Router />
                </BrandingProviderWPP>
              </WPPOsProvider>
              ):(
              <BrandingProvider>
                <Router />
              </BrandingProvider>)}
            </PersistGate>
          </Provider>
        );
      }}
    </ClearCache>
  );
};
export default App;
